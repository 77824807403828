<template>
    <div>
        <ul class="bg-teal-500 flex items-center justify-center">
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/customers/new"><i class="icon-users4 mr-3" style="font-size: 20px"></i>Add Customer</router-link>
            </li>
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/products/create"><i class="icon-stack2 mr-3" style="font-size: 20px"></i>Add Product</router-link>
            </li>
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/purchases/new"><i class="icon-stack mr-3" style="font-size: 20px"></i>Create Purchase Order</router-link>
            </li>
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/quotations/new"><i class="icon-price-tags2 mr-3" style="font-size: 20px"></i>Create Quotation</router-link>
            </li>
        </ul>
        <div class="grid grid-cols-2 m-5 gap-5">
            <!-- PRODUCTS -->
            <div class="col-span-1 bg-white border rounded-sm">
                <div class="flex items-center rounded-t-sm justify-between px-3 py-1 bg-blue-500 text-blue-50">
                    <div class="font-semibold">BEST SELLING</div>
                    <div>Last 30 days</div>
                </div>
                <table class="w-full table-fixed">
                    <tbody class="divide-y">
                        <tr v-for="(product, i) in products" :key="i">
                            <td class="px-3 py-1 truncate" style="width: 80%">
                                <router-link :to="'/products/detail/'+product._id" class="text-blue-500 hover:text-blue-600">{{product.name}}</router-link>
                            </td>
                            <td class="px-3 py-1 text-right">{{product.qty}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-span-1">
                <!-- STAISTICT CUSTOMER & SUPPLIER -->
                <div class="grid grid-cols-2 gap-5 mb-5">
                    <div class="col-span-1">
                        <div class="bg-white border rounded-sm p-5 flex items-center relative">
                            <div>
                                <canvas id="customer"></canvas>
                            </div>
                            <div class="ml-auto text-right">
                                <div class="text-2xl font-semibold">{{Intl.NumberFormat('en-US').format(customerCount)}}</div>
                                <div class="text-xs text-gray-400">TOTAL CUSTOMER</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <div class="bg-white border rounded-sm p-5 flex items-center">
                            <div>
                                <canvas id="supplier"></canvas>
                            </div>
                            <div class="ml-auto text-right">
                                <div class="text-2xl font-semibold">{{Intl.NumberFormat('en-US').format(supplierCount)}}</div>
                                <div class="text-xs text-gray-400">TOTAL SUPPLIER</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-5 mb-5">
                    <div class="bg-white border rounded-sm overflow-hidden">
                        <div class="px-2 py-1 rounded-t-sm bg-green-500 text-green-50 flex justify-between">
                            <div class="font-semibold">SALES ORDER</div>
                            <div>Last 30 days</div>
                        </div>
                        <table  class="w-full">
                            <tbody class="divide-y">
                                <tr v-for="(sale, i) in sales" :key="i">
                                    <td class="px-2 py-1">{{sale._id}}</td>
                                    <td class="px-2 py-1 text-right">{{sale.count}}</td>
                                    <td class="px-2 py-1 text-right">{{Intl.NumberFormat().format(sale.total)}}</td>
                                </tr>
                                <tr>
                                    <td class="px-2 py-1 font-semibold">Total</td>
                                    <td class="px-2 py-1 text-right font-semibold">{{totalCountSales}}</td>
                                    <td class="px-2 py-1 text-right font-semibold">{{Intl.NumberFormat().format(totalSales)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bg-white border rounded-sm">
                        <div class="rounded-sm rounded-b-none px-2 py-1 bg-green-500 text-green-50 flex justify-between">
                            <div class="font-semibold">POINT OF SALES</div>
                            <div>Last 30 days</div>
                        </div>
                        <table  class="w-full">
                            <tbody class="divide-y">
                                <tr v-for="(p,i) in pos" :key="i">
                                    <td class="px-2 py-1">{{p._id}}</td>
                                    <td class="px-2 py-1 text-right">{{p.count}}</td>
                                    <td class="px-2 py-1 text-right">{{Intl.NumberFormat().format(p.total)}}</td>
                                </tr>
                                <tr>
                                    <td class="px-2 py-1 font-semibold">Total</td>
                                    <td class="px-2 py-1 text-right font-semibold">{{totalCountPos}}</td>
                                    <td class="px-2 py-1 text-right font-semibold">{{Intl.NumberFormat().format(totalPos)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="bg-white border rounded-sm">
                    <div class="rounded-sm rounded-b-none px-2 py-1 bg-red-500 text-red-50 flex justify-between">
                        <div class="font-semibold">CUSTOMER INVOICES</div>
                        <div>Last 30 days</div>
                    </div>
                    <table class="w-full">
                        <tbody class="divide-y">
                            <tr v-for="(inv, i) in invoices" :key="i">
                                <td class="px-2 py-1">{{inv._id}}</td>
                                <td class="px-2 py-1 text-right">{{inv.count}}</td>
                                <td class="px-2 py-1 text-right">{{Intl.NumberFormat().format(inv.total)}}</td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1 font-semibold">Total</td>
                                <td class="px-2 py-1 text-right font-semibold">{{totalCountInvoices}}</td>
                                <td class="px-2 py-1 text-right font-semibold">{{Intl.NumberFormat().format(totalInvoices)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- PRODUCT -->
        <div class="grid grid-cols-6 m-5 gap-5">
            <div class="col-span-4">
                <div class="bg-white p-5 rounded-sm border">
                    <div class="flex items-center justify-between">
                        <div>PRODUCTS</div>
                    </div>
                    <canvas id="product" class="border"></canvas>
                </div>
            </div>
            <div class="col-span-2 bg-white rounded-sm border"></div>
        </div>
        <div class="hidden">
            <button @click="onUpdate">UPDATE</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {moment} from '../../moment'
import Chart from 'chart.js/auto'
import breadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
export default {
    mixins: [breadcrumbsManager],
    data () {
        return {
            supplierCount: 0,
            supplierLabel: [],
            supplierData: [],
            customerCount: 0,
            customerLabel: [],
            customerData: [],
            quotationLabel: [],
            quotations: [],
            sales: [],
            pos: [],
            invoices: [],
            products: []
        }
    },
    created () {
        this.setButtonText()
        this.setDiscard(false)
        this.getData()
    },
    mounted () {
        let app = this
        var ctxCustomer = document.getElementById('customer')
        ctxCustomer.width = 50
        var ctxSupplier = document.getElementById('supplier')
        ctxSupplier.width = 50
       
        const getOrCreateTooltip = (chart) => {
            let tooltipEl = chart.canvas.parentNode.querySelector('div');

            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                tooltipEl.style.borderRadius = '3px';
                tooltipEl.style.color = 'white';
                tooltipEl.style.opacity = 1;
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.transform = 'translate(-50%, 0)';
                tooltipEl.style.transition = 'all .1s ease';

                const table = document.createElement('table');
                table.style.margin = '0px';

                tooltipEl.appendChild(table);
                chart.canvas.parentNode.appendChild(tooltipEl);
                
            }

            return tooltipEl;
        };

        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const {chart, tooltip} = context;
            const tooltipEl = getOrCreateTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set Text
            if (tooltip.body) {
                const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map(b => b.lines);

                const tableHead = document.createElement('thead');

                titleLines.forEach(title => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;

                const th = document.createElement('th');
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
                });

                const tableBody = document.createElement('tbody');
                bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '10px';
                span.style.width = '10px';
                span.style.display = 'inline-block';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;

                const text = document.createTextNode(body);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
                });

                const tableRoot = tooltipEl.querySelector('table');

                // Remove old children
                while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
                }

                // Add new children
                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
            }

            // const {offsetLeft: positionX} = chart.canvas;

            // Display, position, and set styles for font
                let a
                let b
                chart.canvas.addEventListener('mousemove', (event) => {
                    chart.canvas.style.cursor = 'pointer'
                    a = event.layerY
                    b = event.layerX
                     if (tooltip.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = b + 75+ 'px';
                    tooltipEl.style.top =  a - 19 + 'px';
                    tooltipEl.style.font = tooltip.options.bodyFont.string;
                    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
                })

        };
        // CUSTOMER STATS
        window.chartCustomer = new Chart(ctxCustomer, {
            type: 'doughnut',
            data: {
                labels: app.customerLabel,
                datasets: [{
                    data: app.customerData,
                    backgroundColor: [
                        'rgb(239,68,68)',
                        'rgb(245,158,11)',
                        'rgb(17,185,129)',
                        'rgb(5,182,211)',
                        'rgb(255, 205, 86)'
                    ],
                }]
            },
            options: {
                plugins: {
					legend: {
						display: false,
					},
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    }
				},
            }
        })
        // SUPPLIER STATS
        window.chartSupplier = new Chart(ctxSupplier, {
            type: 'doughnut',
            data: {
                labels: app.supplierLabel,
                datasets: [{
                    data: app.supplierData,
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(202,203,207)',
                        'rgb(153,102,255)',
                        'rgb(54,162,235)',
                        'rgb(236,72,153)',
                        'rgb(59,129,246)'
                    ],
                }]
            },
            options: {
                plugins: {
					legend: {
						display: false,
					},
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    }
				},
            }
        })
        
        var ctxProduct = document.getElementById('product')
        ctxProduct.height = 110
        new Chart (ctxProduct, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                datasets: [
                    {
                        data: [10, 12, 28, 49, 44, 32, 29, 34],
                        borderColor: 'rgb(20,184,166)',
                    },
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    },
    computed: {
        totalCountSales () {
            let total = 0
            for(let i = 0; i < this.sales.length; i++) {
                total += this.sales[i].count
            }
            return total
        },
        totalSales () {
            let total = 0
            for(let i = 0; i < this.sales.length; i++) {
                total += this.sales[i].total
            }
            return total
        },
        totalCountPos () {
            let total = 0
            for(let i = 0; i < this.pos.length; i++) {
                total += this.pos[i].count
            }
            return total
        },
        totalPos () {
            let total = 0
            for(let i = 0; i < this.pos.length; i++) {
                total += this.pos[i].total
            }
            return total
        },
        totalCountInvoices () {
            let total = 0
            for (let i = 0; i < this.invoices.length; i++) {
                total += this.invoices[i].count
            }
            return total
        },
        totalInvoices () {
            let total = 0
            for (let i = 0; i < this.invoices.length; i++) {
                total += this.invoices[i].total
            }
            return total
        }
    },
    methods: {
        getData () {
            axios.get('/dashboard')
            .then(res => {
                console.log(res);
                this.sales = res.data.sales
                this.pos = res.data.pos
                this.supplierCount = res.data.suppliers
                this.invoices = res.data.invoices
                this.products = res.data.products
                let customers = res.data.customers
                for(let i = 0; i < customers.length; i++) {
                    let customer = customers[i]
                    this.customerLabel.push(customer._id)
                    this.customerData.push(customer.count)
                    this.customerCount += customer.count
                }
                this.$nextTick(() => {
                    window.chartCustomer.update()
                })
            })
        },
        getDate (d) {
            return moment(d)
        },
        onUpdate () {
            axios.put('/update')
        }
    }
}
</script>